/* eslint-disable no-undef */
/* eslint-disable no-console */

'use strict';

var isActiveExperiment = function () {
    var optimizelyExperimentId = $('.optimizely-url').data('optimizelyexp');
    var optimizelyVariationId = $('.optimizely-url').data('optimizelyvar');
    var optimizelyVars;

    if (window.optimizely) {
        optimizelyVars = window.optimizely.get('state').getVariationMap();
    }

    return optimizelyVars !== undefined && optimizelyVars[optimizelyExperimentId] !== undefined && optimizelyVars[optimizelyExperimentId].name && optimizelyVars[optimizelyExperimentId].id === optimizelyVariationId.toString();
};

var hasMemberStatusChanged = function () {
    if (window.utag_data) {
        var isMember = window.utag_data.customer_status !== 'Not Logged In';
        var storageIsMember = sessionStorage.getItem('isMember');

        return isMember.toString() !== storageIsMember;
    }
    return null;
};

var makeCall = function (url) {
    // login/logout occured
    if (hasMemberStatusChanged()) {
        var isMember = window.utag_data.customer_status !== 'Not Logged In';

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            success: function (data) {
                if (!data.success) {
                    console.error('Error with optimizely ABTest initial Customer Group Assignment. Error: ' + data.message);
                } else {
                    sessionStorage.setItem('isMember', isMember);
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    console.error('Error with optimizely ABTest initial Customer Group Assignment. Error: ' + err.message);
                }
            }
        });
    }
};

/**
 * Add customer to inital customer group
 */
function initalCustomerGroupAssignment() {
    document.onreadystatechange = function () {
        if (document.readyState === 'complete') {
            var activeExperiment = isActiveExperiment();
            var url = $('.optimizely-url');
            var optimizely = window.optimizely;

            if (activeExperiment && url.length > 0 && optimizely) {
                var optimizelyUrl = $('.optimizely-url').data('optimizelyurl');

                makeCall(optimizelyUrl);
            }
        }
    };
}

module.exports = {
    initalCustomerGroupAssignment: initalCustomerGroupAssignment
};
